<template>
    <main class="page">
        <section class="offerta">
            <div class="offerta__container">
                <div field="text" class="t-text t-text_md" style="">
                    <br /><br />
                    <p style="text-align: center">
                        <strong style="font-size: 22px"><span class="ql-cursor"></span>{{ offertaContent.title }}</strong
                        ><br />
                        <strong style="font-size: 22px"><span class="ql-cursor"></span>Правила оплати, доставки та повернення</strong>
                    </p>
                    <br />
                    <p></p>

                    <div v-html="offertaContent.content"></div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            offertaContent: "",
        };
    },
    created() {
        this.fetchoffertaContent();
    },
    methods: {
        async fetchoffertaContent() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_PROD_API_URL}page/offerta`);
                this.offertaContent = response.data;
            } catch (error) {
                console.error("Error fetching offerta content:", error);
            }
        },
    },
};
</script>

<style></style>

import axios from 'axios';

export const promoCode = {
    state: () => ({
        promoCode: '',
        discountType: null,
        discountData: 0,
        promocodeStatusText: '',
        promocodeStatusColor: '',
    }),
    getters: {
        promoCode: state => state.promoCode,
        discountType: state => state.discountType,
        discountData: state => state.discountData,
        promocodeStatusText: state => state.promocodeStatusText,
        promocodeStatusColor: state => state.promocodeStatusColor,
    },
    mutations: {
        SET_PROMO_CODE(state, promoCode) {
            state.promoCode = promoCode;
        },
        SET_DISCOUNT_TYPE(state, discountType) {
            state.discountType = discountType;
        },
        SET_DISCOUNT_DATA(state, discountData) {
            state.discountData = discountData;
        },
        SET_PROMOCODE_STATUS_TEXT(state, text) {
            state.promocodeStatusText = text;
        },
        SET_PROMOCODE_STATUS_COLOR(state, color) {
            state.promocodeStatusColor = color;
        },
    },
    actions: {
        async checkPromoCode({ commit, state, rootGetters }) {
            if (!state.promoCode) {
                commit('SET_DISCOUNT_TYPE', null);
                commit('SET_DISCOUNT_DATA', 0);
                return;
            }

            try {
                const cartAmount = rootGetters['totalPriceCart'];
                const productPackageIds = rootGetters['getProductPackageIds'];
                const productIds = rootGetters['getProductIds'];
                console.log('productIds', productIds);

                const response = await axios.post(process.env.VUE_APP_PROD_API_URL + "promocode", {
                    code: state.promoCode,
                    cartAmount: cartAmount,
                    productPackageIds: productPackageIds,
                    productIds: productIds,
                });

                if (response.data.error) {
                    commit('SET_PROMOCODE_STATUS_TEXT', response.data.error);
                    commit('SET_PROMOCODE_STATUS_COLOR', "red");

                    commit('SET_DISCOUNT_TYPE', null);
                    commit('SET_DISCOUNT_DATA', 0);
                } else {
                    commit('SET_PROMOCODE_STATUS_TEXT', response.data.message);
                    commit('SET_PROMOCODE_STATUS_COLOR', "green");

                    commit('SET_DISCOUNT_TYPE', response.data.type);
                    commit('SET_DISCOUNT_DATA', response.data.data);
                }
            } catch (error) {
                console.error(error);
                commit('SET_PROMOCODE_STATUS_TEXT', "Промокод не дійсний");
                commit('SET_PROMOCODE_STATUS_COLOR', "red");
                commit('SET_DISCOUNT_TYPE', null);
                commit('SET_DISCOUNT_DATA', 0);
            }
        },
    },
};
<script setup>
import { useI18n } from "vue-i18n";

const { t } = useI18n({ useScope: "global" });
</script>

<template>
    <div id="app" :data-lang="t('language')">
        <Header />
        <div class="main">
            <router-view></router-view>
        </div>
        <Footer />
    </div>
    <!-- <Firebase /> -->
</template>

<script>
/* global KeyCRM */

import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
// import Firebase from "./components/FirebaseComponent.vue";

export default {
    name: "App",
    components: {
        Header,
        Footer,
        // Firebase,
    },
    mounted() {
        this.saveUTMParams();
        this.$store.dispatch("fetchProductPackage");
        this.$store.dispatch("fetchProductsTagsNoPromo");
        this.loadChatWidget();
        this.generateAndSaveExternalId();
        this.checkAndCleanCart();

        this.checkAppVersion();
        setInterval(this.checkAppVersion, 60000);
        if (!window.KTracking) {
            window.KTracking = {
                collectNonUniqueClicks: false,
                multiDomain: false,
                R_PATH: "https://kt.trafficjack.team/RC21XY",
                P_PATH: "https://kt.trafficjack.team/50f7fac/postback",
                listeners: [],
                reportConversion: function () {
                    this.queued = arguments;
                },
                getSubId: function (fn) {
                    this.listeners.push(fn);
                },
                ready: function (fn) {
                    this.listeners.push(fn);
                },
            };
        }
        (function () {
            var a = document.createElement("script");
            a.type = "application/javascript";
            a.async = true;
            a.src = "https://kt.trafficjack.team/js/k.min.js";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(a, s);
        })();
    },

    data() {
        return {};
    },
    methods: {
        loadChatWidget() {
            (function (w, d, t, u, c) {
                var s = d.createElement(t),
                    j = d.getElementsByTagName(t)[0];
                s.src = u;
                s.async = true;
                s.defer = true;
                s.onload = () => KeyCRM.render(c);
                j.parentNode.insertBefore(s, j);
            })(window, document, "script", "https://chat.key.live/bundles/widget.min.js", { token: "07125581-5055-49fd-962c-e68cc29cec34" });
        },
        saveUTMParams() {
            const urlParams = new URLSearchParams(window.location.search);
            const utmParams = ["utm_source", "utm_content", "utm_medium", "utm_campaign", "utm_term"];
            const utmData = {};

            utmParams.forEach((param) => {
                if (urlParams.has(param)) {
                    utmData[param] = urlParams.get(param);
                }
            });

            // Если utm_source пустой, используем document.referrer
            // if (!utmData.utm_source) {
            //     utmData.utm_source = document.referrer;
            // }

            if (Object.keys(utmData).length > 0) {
                document.cookie = `utm=${encodeURIComponent(JSON.stringify(utmData))}; max-age=${7 * 24 * 60 * 60}; path=/`;
            }
        },

        generateAndSaveExternalId() {
            // Проверка существования external_id в localStorage
            let externalId = localStorage.getItem("external_id");
            if (!externalId) {
                // Генерация нового external_id, например, с использованием случайных чисел
                externalId = `id_${Math.random().toString(36).substr(2, 9)}`;
                // Сохранение нового external_id в localStorage
                localStorage.setItem("external_id", externalId);
            }
            // Возвращение external_id (нового или существующего)
            return externalId;
        },

        checkAndCleanCart() {
            const cartStr = localStorage.getItem("cart");
            if (cartStr) {
                const cart = JSON.parse(cartStr);
                let isValid = true; // Флаг для отслеживания наличия price_origin

                for (const item of cart) {
                    // Проверяем наличие version_update у товара
                    if (item.version_update === undefined) {
                        isValid = false;
                        break;
                    }

                    if (!isValid) break; // Прекращаем проверку, если найден элемент без price_origin
                }

                // Удаляем корзину из localStorage, если найден товар или вариант без price_origin
                if (!isValid) {
                    localStorage.removeItem("cart");
                    console.log("Корзина удалена из-за отсутствия version_update || is_set");
                }
            }
        },

        async checkAppVersion() {
            if (!process.env.VUE_APP_VERSION) {
                console.error("VUE_APP_VERSION не определен.");
                return;
            }

            try {
                // Получаем версию приложения с сервера
                const response = await fetch(process.env.VUE_APP_PROD_API_URL + "version");
                const data = await response.json();

                if (!data.version) {
                    console.error("Версия из ответа отсутствует.");
                    return;
                }

                if (data.version !== process.env.VUE_APP_VERSION) {
                    window.location.reload(true);
                }
            } catch (error) {
                console.error("Ошибка при проверке версии приложения:", error);
            }
        },
    },
};
</script>

<style lang="scss">
#app {
}
@media (min-width: 768px) {
    .KeyCRM-toggleButtonContainer {
        right: 70px !important;
        // bottom: 100px !important;
        transform: scale(1.2);
    }
}
@media (max-width: 768px) {
    .KeyCRM-chatWindowContainer {
        right: 0 !important;
    }
}
</style>

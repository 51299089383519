<template>
    <div class="top-page">
        <div class="top-page__container">
            <div class="top-page__breadcrumbs breadcrumbs">
                <ul class="breadcrumbs__list">
                    <li class="breadcrumbs__item"><router-link to="/" class="breadcrumbs__link">Le’Perle</router-link></li>
                    <li class="breadcrumbs__item">
                        <span class="breadcrumbs__current">Пошук</span>
                    </li>
                </ul>
            </div>
            <div class="top-page__body">
                <h1 class="top-page__title" data-title="SEARCH">Пошук "{{ searchQuery }}"</h1>

                <div class="top-page__control">
                    <Sorting @sortingChanged="handleSortingChange" style="display: none" />

                    <button class="top-page__button top-page__button_hide _icon-filter" @click="toggleOpenFilter">Фільтри</button>
                </div>
            </div>
        </div>
    </div>

    <section class="catalog">
        <div class="catalog__container">
            <div class="catalog__body">
                <div class="catalog__content">
                    <div class="catalog__items product-items">
                        <ProductCart v-for="(product, index) in products" :key="index" :product="product" />
                    </div>
                    <div class="more">
                        <div class="more__pagination pagination-more">
                            <a href="#" class="pagination-more__item" v-for="page in paginate()" :key="page" :class="{ active: currentPage === page }" @click.prevent="page !== '...' && handlePageChange(page)">
                                {{ page }}
                            </a>
                        </div>

                        <!-- <div class="more__pagination pagination-more">
                            <a href="#" class="pagination-more__item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }" @click.prevent="handlePageChange(page)">{{ page }}</a>
                        </div> -->

                        <div class="more__body">
                            <button class="more__button button" v-if="currentPage < totalPages" @click="loadMore">Показати більше</button>

                            <div class="more__view">
                                переглянуто
                                <b>{{ products.length }}</b>
                                товарів з
                                <b>{{ totalProducts }}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <ProductSlider :title="'Інші клієнти також купили'" :products="viewedProducts" id="1" />
    <ProductSlider :title="'Останні переглянуті товари'" :products="viewedProducts" id="2" />
</template>

<script>
import Sorting from "./Sorting.vue";
import ProductCart from "./ProductCart.vue";
import ProductSlider from "./ProductSlider.vue";
export default {
    components: {
        Sorting,
        ProductCart,
        ProductSlider,
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_PROD_API_URL,
            products: [],
            currentCategoryTitle: "",
            currentPage: 1,
            totalPages: 0,
            totalProducts: 0,
            viewedProducts: [],
            searchQuery: "",
        };
    },
    mounted() {
        this.currentPage = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
        this.getProducts();
        this.getViewProducts();
    },
    methods: {
        handlePriceChange(prices) {
            this.currentPage = 1;

            // Заменяем текущий URL новым, включающим ценовые фильтры
            this.$router.replace({ query: { ...this.$route.query, prices: JSON.stringify(prices) } }).then(() => this.getProducts());
        },
        handleSortingChange(sorting) {
            this.currentPage = 1;

            // Заменяем текущий URL новым, включающим сортировку
            this.$router.replace({ query: { ...this.$route.query, sorting } }).then(() => this.getProducts());
        },

        getProducts(append = false) {
            // Если в URL есть параметры фильтра, используем их. В противном случае используем активные фильтры.

            const prices = this.$route.query.prices ? JSON.parse(this.$route.query.prices) : { minPrice: this.minPrice, maxPrice: this.maxPrice };
            const sorting = this.$route.query.sorting || "default"; // Вот тут мы получаем значение сортировки

            this.axios
                .get(this.apiUrl + "products/search", {
                    params: {
                        name: this.$route.params.name,
                        min_price: prices.minPrice,
                        max_price: prices.maxPrice,
                        page: this.currentPage,
                        sorting: sorting,
                    },
                })
                .then((res) => {
                    if (append) {
                        this.products = this.products.concat(res.data.data);
                    } else {
                        this.products = res.data.data;
                    }

                    this.currentPage = res.data.meta.current_page;
                    this.totalPages = res.data.meta.last_page;
                    this.totalProducts = res.data.meta.total;
                    this.searchQuery = this.$route.params.name;
                });
        },
        handlePageChange(page) {
            this.currentPage = page;
            // Заменяем текущий URL новым, включающим номер страницы
            this.$router.replace({ query: { ...this.$route.query, page: this.currentPage } }).then(() => this.getProducts());
        },
        loadMore() {
            if (this.currentPage < this.totalPages) {
                this.currentPage += 1;
                // Заменяем текущий URL новым, включающим номер страницы
                this.$router.replace({ query: { ...this.$route.query, page: this.currentPage } });
                // Удаляем вызов this.getProducts(true)
            }
        },

        getViewProducts() {
            let storedProducts = localStorage.getItem("viewedProducts");
            if (storedProducts) {
                this.viewedProducts = JSON.parse(storedProducts);
            }

            return this.viewedProducts;
        },
        paginate() {
            let pages = [];

            if (this.totalPages <= 10) {
                // Если страниц меньше или равно 10, показываем все
                for (let i = 1; i <= this.totalPages; i++) {
                    pages.push(i);
                }
            } else {
                // Добавляем первую страницу
                pages.push(1);

                // Определяем диапазон отображаемых страниц вокруг текущей страницы
                let startPage = Math.max(this.currentPage - 2, 2);
                let endPage = Math.min(startPage + 4, this.totalPages - 1);

                if (this.currentPage - 1 <= 3) {
                    endPage = 6;
                } else if (this.totalPages - this.currentPage <= 3) {
                    startPage = this.totalPages - 5;
                }

                // Добавляем "..."
                if (startPage > 2) {
                    pages.push("...");
                }

                // Добавляем страницы между первой и последней
                for (let i = startPage; i <= endPage; i++) {
                    pages.push(i);
                }

                // Добавляем "..." и последнюю страницу, если необходимо
                if (endPage < this.totalPages - 1) {
                    pages.push("...");
                }
                pages.push(this.totalPages);
            }

            return pages;
        },
    },
    watch: {
        "$route.query.page": function (newVal, oldVal) {
            if (newVal !== oldVal) {
                const newPage = newVal ? parseInt(newVal) : 1;
                const oldPage = oldVal ? parseInt(oldVal) : 1;
                const append = newPage > oldPage;
                this.currentPage = newPage;
                this.getProducts(append);
            }
        },
        "$route.params.name": function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getProducts();
            }
        },
    },
};
</script>

<style></style>

<template>
    <article class="product-cart" v-if="product">
        <div class="product-cart__images-ibg">
            <router-link :to="{ name: 'Product', params: { slug: product.slug } }">
                <img v-lazy="getImageProduct(product)" :style="getImageStyle(product)" :alt="product.name" />
            </router-link>
            <div class="product-cart__labels">
                <button class="product-cart__label _icon-favorite" :class="{ active: isInWishlist }" @click="toggleWishlist(product)"></button>
            </div>
            <div class="product-cart__labels2" v-if="product.old_price">{{ Math.round(((oldPrice - price) / oldPrice) * 100) }}%</div>
            <div class="product-cart__labels3" v-if="Array.isArray(product.category) && product.category.some((category) => category.id == 2)">ХІТ</div>
        </div>
        <div class="product-cart__info">
            <h3 class="product-cart__title">
                <router-link :to="{ name: 'Product', params: { slug: product.slug } }">{{ product.name }}</router-link>
            </h3>
            <div class="product-cart__materials" v-if="product._materials">
                <span>
                    {{ product._materials }}
                </span>
            </div>
            <div class="product-cart__materials" v-else-if="product.materials && Object.keys(product.materials).length">
                <span>
                    {{ [].concat(...Object.values(product.materials || {}).map((group) => group.map((item) => item.value))).join(", ") }}
                </span>
            </div>
            <div class="product-cart__materials" v-else>
                <span>
                    {{ (product.material || []).map((item) => item.value).join(", ") }}
                </span>
            </div>
        </div>
        <div class="product-cart__price price">
            <div :class="{ price__current: true, sale: true }">{{ price }} грн.</div>
            <div v-if="product.old_price" class="price__old">{{ oldPrice }} грн.</div>
        </div>
        <div class="product-cart__button">
            <button class="button" @click="addToCart(product)">
                <span>
                    <!-- <img src="../assets/img/pautina.png" alt="" /> -->
                    Додати в кошик
                </span>
            </button>
        </div>
    </article>
</template>

<script>
/* global dataLayer */

import { useStore } from "vuex";
import { useRouter } from "vue-router"; // Добавьте этот импорт
import "vue-toast-notification/dist/theme-bootstrap.css";
import { useToast } from "vue-toast-notification";
import { computed, getCurrentInstance } from "vue";
import axios from "axios";

export default {
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isInWishlist: false,
        };
    },
    setup(props) {
        const store = useStore();
        const router = useRouter(); // Получите экземпляр роутера
        const toast = useToast();
        const totalPrice = computed(() => store.getters.totalPriceCart);

        const component = getCurrentInstance(); // Получение доступа к экземпляру компонента
        const apiUrl = process.env.VUE_APP_PROD_API_URL;

        const getCookie = component.appContext.config.globalProperties.$getCookie;
        const getEventId = component.appContext.config.globalProperties.$getEventId;
        const event_id = getEventId();
        const external_id = localStorage.getItem("external_id");
        // const discountPrecentAdditional = component.appContext.config.globalProperties.$discountPrecentAdditional;

        const price = computed(() => {
            if (props.product.is_set === 1 && props.product.sets.length > 0) {
                const setsPorducCount = props.product.sets.length;
                var discount = 0;
                if (setsPorducCount === 2) {
                    discount = 0.1;
                } else if (setsPorducCount >= 3) {
                    discount = 0.2;
                }
                return Object.values(props.product.sets).reduce((total, set) => total + Math.round(set.price_origin * (1 - discount)), 0);
            } else {
                return Math.round(props.product.price);
            }
        });

        const oldPrice = computed(() => {
            if (props.product.is_set === 1 && props.product.sets.length > 0) {
                return Object.values(props.product.sets).reduce((total, set) => total + Math.round(set.old_price), 0);
            } else {
                return Math.round(props.product.old_price);
            }
        });

        const addToCart = (product) => {
            const hasVariants = product.variants && product.variants.length > 0;
            const hasSets = product.is_set == 1;

            if (hasVariants || hasSets) {
                // Если есть вариации, перенаправляем на страницу товара
                router.push({ name: "Product", params: { slug: product.slug } });
            } else {
                // Если вариаций нет, добавляем товар в корзину
                let cart = localStorage.getItem("cart");
                if (cart) {
                    cart = JSON.parse(cart);
                } else {
                    cart = [];
                }

                const itemToAdd = {
                    id: product.id,
                    version_update: 1,
                    product_id: product.id,
                    is_set: 0,
                    variant_id: null,
                    vendor_code: product.vendor_code,
                    price: product.price,
                    percent: product.percent,
                    quantity: 1,
                    product: product, // И здесь
                    variant: [],
                };

                const existingProductIndex = cart.findIndex((item) => item.id === itemToAdd.id);
                if (existingProductIndex >= 0) {
                    cart[existingProductIndex].quantity++;
                } else {
                    cart.push(itemToAdd);
                }

                store.dispatch("updateCart", cart);

                dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
                dataLayer.push({
                    event: "add_to_cart",
                    ecommerce: {
                        value: totalPrice.value,
                        currency: "UAH",
                        items: [
                            {
                                item_name: product.name, // Name or ID is required.
                                item_id: product.vendor_code,
                                price: parseFloat(product?.price),
                                item_brand: "Le-perle",
                                item_category: product.category?.[0]?.title || "",
                                item_variant: product.vendor_code,
                                quantity: 1,
                            },
                        ],
                    },
                });

                window.fbq("track", "AddToCart", {
                    content_ids: product.vendor_code,
                    content_type: "product",
                    external_id: external_id,
                    event_id: event_id,
                    content_name: product.name,
                    currency: "UAH",
                    source: "le-perle.com.ua",
                    value: parseFloat(product.price),
                });

                try {
                    axios.post(apiUrl + "fbapi", {
                        action: "AddToCart",
                        event_id: event_id,
                        value: parseFloat(product.price),
                        content_name: product.name,
                        content_ids: product.vendor_code,
                        quantity: 1,
                        fbc: getCookie("_fbc"),
                        fbp: getCookie("_fbp"),
                        external_id: external_id,
                        useragent: window.navigator.userAgent,
                        url: `${window.location.origin}${window.location.pathname}`,
                    });
                } catch (error) {
                    console.error(error);
                }

                localStorage.setItem("cart", JSON.stringify(cart));
                document.dispatchEvent(new CustomEvent("cart-updated"));
                store.commit("openCart");
            }
        };
        return { addToCart, toast, price, oldPrice };
    },
    methods: {
        getImageProduct(product) {
            const downloadWithPositionOne = product.downloads.find((d) => d.position === 1);
            const firstDownload = product.downloads[0]; // Первое изображение в массиве

            if (downloadWithPositionOne?.path) {
                return "https://ig.le-perle.com.ua/rs/?width=640&height=0&path=" + downloadWithPositionOne.path;
                // return "https://img.le-perle.com.ua/image-resizing?width=380&image=" + this.$uploadUrl + downloadWithPositionOne.path;
            } else if (firstDownload?.path) {
                return "https://ig.le-perle.com.ua/rs/?width=640&height=0&path=" + firstDownload.path;
                // return "https://img.le-perle.com.ua/image-resizing?width=380&image=" + this.$uploadUrl + firstDownload.path;
            } else {
                return "https://api.le-perle.com.ua/assets/img/logo.svg";
            }
        },
        getImageStyle(product) {
            const downloadWithPositionOne = product.downloads.find((d) => d.position === 1);

            if (downloadWithPositionOne && downloadWithPositionOne.provisions !== null) {
                return { "object-position": downloadWithPositionOne.provisions };
            }
            return {};
        },
        toggleWishlist(product) {
            let wishlist = localStorage.getItem("wishlist");
            if (wishlist) {
                wishlist = JSON.parse(wishlist);
            } else {
                wishlist = [];
            }

            // Проверим, находится ли товар уже в списке избранных
            if (wishlist.some((wishItem) => wishItem.id === product.id)) {
                wishlist = wishlist.filter((wishItem) => wishItem.id !== product.id);
                this.isInWishlist = false;
                this.toast.error(product.name + " <b>видалено з обраного!</b>");
            } else {
                wishlist.push(product);
                this.isInWishlist = true;
                this.toast.success(product.name + " <b>додано до обраного!</b>");
            }
            localStorage.setItem("wishlist", JSON.stringify(wishlist));
        },
    },
    mounted() {
        let wishlist = localStorage.getItem("wishlist");

        if (wishlist) {
            wishlist = JSON.parse(wishlist);
            this.isInWishlist = wishlist.some((wishItem) => wishItem.id === this.product.id);
        }
    },
};
</script>

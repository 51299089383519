<template>
    <main class="page">
        <section class="delivery">
            <div class="delivery__container">
                <div field="text" class="t-text t-text_md" style="">
                    <br />
                    <p style="text-align: center">
                        <strong style="font-size: 22px">{{ deliveryContent.title }}</strong>
                    </p>
                    <br />
                    <div class="t-text t-text_md" v-html="deliveryContent.content"></div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            deliveryContent: "",
        };
    },
    created() {
        this.fetchDeliveryContent();
    },
    methods: {
        async fetchDeliveryContent() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_PROD_API_URL}page/delivery`);
                this.deliveryContent = response.data;
            } catch (error) {
                console.error("Error fetching delivery content:", error);
            }
        },
    },
};
</script>

<style>
.delivery {
    margin-bottom: 20px;
}
.delivery h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
}
</style>

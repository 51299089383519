<template>
    <main class="page">
        <section class="contacts">
            <div class="contacts__container">
                <h1></h1>
                <div class="contacts__row">
                    <!-- <div class="contacts__col">
                        <img src="https://silvertouch.co.ua/assets/img/contacts.jpg" alt="contacts.jpg" width="300" />
                    </div> -->
                    <div class="contacts__col" v-html="contactsContent.content"></div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            contactsContent: "",
        };
    },
    created() {
        this.fetchcontactsContent();
    },
    methods: {
        async fetchcontactsContent() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_PROD_API_URL}page/contacts`);
                this.contactsContent = response.data;
            } catch (error) {
                console.error("Error fetching offerta content:", error);
            }
        },
    },
};
</script>

<style>
.contacts {
    padding: 50px 0;
}

.contacts__row h2 {
    font-size: 30px;
    font-weight: 700;
}

.contacts__row p {
    font-size: 18px;
    margin-bottom: 10px;
}

.contacts__row {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 50px;
}

.contacts__container {
    max-width: 800px;
}

.contacts__col {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

@media (max-width: 768px) {
    .contacts__row {
        flex-direction: column;
        row-gap: 20px;
    }

    .contacts__row h2 {
        font-size: 24px;
    }

    .contacts__row p {
        font-size: 16px;
    }
}
</style>

<template>
    <div @click="open()" class="roulette-gift-button" v-if="!popupRouletteShow && !CheckReceivDiscount()">
        <img src="../assets/icons/gift.svg" alt="" />
        <div class="roulette-gift-button-message" @click="hiddenMessage()" v-if="showGiftMessage">Отримай бонус!</div>
    </div>
    <div class="popup" :class="{ 'popup-show': popupRouletteShow }">
        <div class="popup__wrapper">
            <confetti-explosion v-if="visibleConfetti"></confetti-explosion>
            <div class="popup__content">
                <div class="cart__top top-cart">
                    <div></div>
                    <button type="button" class="popup__close _icon-close" @click="popupRouletteShow = 0"></button>
                </div>
                <div class="popup__roulette-content">
                    <div class="popup__roulette-col">
                        <div class="popup__roulette-roulette">
                            <roulette-component ref="rouletteComponent" @wheelStopped="handleWheelStopped"></roulette-component>
                        </div>
                    </div>
                    <div class="popup__roulette-col" v-if="!discountReceived">
                        <div class="popup__roulette-header">
                            <h2>Вигравай знижку!</h2>
                            <p>Крути колесо фортуни та отримай призи! Для участі введи свої данні:</p>
                        </div>
                        <div class="popup__roulette-form">
                            <input class="input" name="firstName" type="text" v-model="fieldfirstName" placeholder="Ваше ім’я *" required />
                            <input class="input" name="phone" v-mask="'+38 (###) ###-##-##'" type="text" v-model="fieldPhone" placeholder="Телефон *" required />
                            <input class="input" :class="{ 'input-error': !emailValid }" name="email" type="email" v-model="fieldEmail" placeholder="E-mail *" required />
                            <button class="button" @click="launchWheel">Крутити</button>
                            <Chekbox isChekedDefault="true"
                                ><span>Я погоджуюся з <router-link :to="{ name: 'Offerta' }" target="_blank">договором оферти і політикою конфіденційності</router-link></span></Chekbox
                            >
                        </div>
                    </div>
                    <div class="popup__roulette-col" v-if="discountReceived">
                        <div class="popup__roulette-header">
                            <h2>Вітаємо! <br />Ви виграли знижку {{ discount }} <br />Ваш промокод: {{ discountPromocode }}</h2>
                            <button class="button" @click="popupRouletteShow = 0">
                                <span> Обрати прикрасу </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RouletteComponent from "./RouletteComponent.vue";
import Chekbox from "./Chekbox";
import { useToast } from "vue-toast-notification";
import axios from "axios";
import ConfettiExplosion from "vue-confetti-explosion";
import { mask } from "vue-the-mask";

export default {
    setup() {
        const toast = useToast();
        return { toast };
    },
    name: "RoulettePopup",
    components: { RouletteComponent, Chekbox, ConfettiExplosion },
    directives: { mask },
    data() {
        return {
            apiUrl: process.env.VUE_APP_PROD_API_URL,
            popupRouletteShow: 0,
            rouletteComponent: null, // Добавьте это
            fieldfirstName: "",
            fieldPhone: "",
            fieldEmail: "",
            submitForm: false,
            discountReceived: false,
            discount: "",
            discountPromocode: "",
            visibleConfetti: false,
            emailValid: true,
            showGiftMessage: false,
            giftMessageShown: false, // Добавляем новое состояние
        };
    },
    methods: {
        validateEmail(email) {
            const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            this.emailValid = re.test(email);
        },
        launchWheel() {
            this.validateEmail(this.fieldEmail); // Вызываем валидацию email

            if (this.submitForm) return;

            if (this.fieldfirstName.length > 0 && this.fieldPhone.length > 0 && this.fieldEmail.length > 0 && !this.submitForm) {
                if (!this.emailValid) {
                    this.toast.error("Введіть коректну електронну адресу.");
                    return;
                }

                this.isVisibleContacts = !this.isVisibleContacts;
                this.$refs.rouletteComponent.spin();
                this.submitForm = true;
            } else {
                this.toast.error("Будь ласка, заповніть всі обов'язкові поля.");
            }
        },
        getUTMData() {
            const cookies = document.cookie.split(";");
            let utmData = {};

            for (let i = 0; i < cookies.length; i++) {
                const [name, value] = cookies[i].trim().split("=");
                if (name === "utm") {
                    utmData = JSON.parse(decodeURIComponent(value));
                    break;
                }
            }

            return utmData;
        },
        handleWheelStopped(text) {
            const utmData = this.getUTMData(); // Получение UTM данных из cookie
            let formData = {
                precent: text,
                email: this.fieldEmail,
                phone: this.fieldPhone,
                name: this.fieldfirstName,
                withCredentials: true,
            };

            // Добавление UTM-меток в formData
            Object.keys(utmData).forEach((key) => {
                formData[key] = utmData[key];
            });

            axios
                .post(this.apiUrl + "promocode/generate", formData)
                .then((response) => {
                    this.visibleConfetti = true;
                    this.discountPromocode = response.data.code;
                    this.discount = text;
                    this.discountReceived = true;
                    this.setCookie("discountReceived", true, 1);
                })
                .catch((error) => {
                    console.error("Ошибка при запросе:", error);
                });
        },
        openPopup() {
            const popupShown = this.getCookie("popupShown");
            const discountReceived = this.getCookie("discountReceived");

            if (popupShown || discountReceived) return;

            setTimeout(() => {
                this.popupRouletteShow = 1;
                this.setCookie("popupShown", true, 1); // Устанавливаем куки на 1 день
            }, 30000); // 30 секунд
        },
        setCookie(name, value, days) {
            const expires = new Date(Date.now() + days * 864e5).toUTCString();
            document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
        },
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return decodeURIComponent(parts.pop().split(";").shift());
            return null;
        },
        open() {
            this.popupRouletteShow = 1;
        },
        handleScroll() {
            if (!this.giftMessageShown && window.scrollY > 500) {
                this.showGiftMessage = true;
                this.giftMessageShown = true; // Устанавливаем флаг, чтобы не скрывать сообщение
            }
        },
        hiddenMessage() {
            this.showGiftMessage = false;
        },
        CheckReceivDiscount() {
            const discountReceived = this.getCookie("discountReceived");
            return discountReceived === "true";
        },
    },
    mounted() {
        this.openPopup();
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        // Заменяем beforeDestroy на beforeUnmount
        window.removeEventListener("scroll", this.handleScroll);
    },
};
</script>
<style lang="scss" scoped>
.popup__roulette-content {
    display: flex;
    align-items: center;
    padding: 0 10px;
    padding-top: 40px;
    padding-bottom: 40px;
}

@media (max-width: 768px) {
    .popup__roulette-content {
        flex-direction: column;
        row-gap: 20px;
    }
    .popup__roulette-roulette {
        display: flex;
        justify-content: center;
    }
}

.popup__roulette-header {
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 25px;
}

.popup__roulette-header h2 {
    font-size: 30px;
    font-weight: bold;
}

.popup__roulette-header p {
    font-size: 16px;
}

.popup__roulette-form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.popup__roulette-form .input {
    border-radius: 6px !important;
    border: 1px solid #000000bd;
}
.popup__roulette-form .button {
    width: 100%;
}
.popup__roulette-col {
    width: 100%;
}
.input-error {
    border-color: red !important;
}

/* roulette-gift-button */

.roulette-gift-button {
    position: fixed;
    z-index: 999;
    right: 65px;
    left: auto;
    bottom: 85px;
    background: #ff9fde;
    width: 60px;
    height: 60px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    transition: background 0.3s;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 8px;

    &:hover {
        background: #ffd2f0;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }

    img {
        width: 30px;
        height: 30px;
    }

    @media (max-width: $mobile) {
        right: 20px;
        bottom: 75px;

        width: 50px;
        height: 50px;

        img {
            width: 25px;
            height: 25px;
        }
    }

    .roulette-gift-button-message {
        position: absolute;
        top: -50px;
        left: -20%; /* Центрируем */
        transform: translateX(-50%);
        background-color: #ff9fde;
        color: #333; /* Цвет текста для читабельности */
        padding: 10px 20px;
        border-radius: 20px;
        text-align: center;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        white-space: nowrap; /* Для предотвращения переноса текста */
        font-size: 12px;
        &:after {
            content: "";
            position: absolute;
            bottom: -19px; /* Расположить стрелку под текстом */
            right: 20px; /* Расположить справа */
            width: 0;
            height: 0;
            border-width: 10px;
            border-style: solid;
            border-color: #ff9fde transparent transparent transparent; /* Верхний треугольник */
        }

        @media (max-width: $mobile) {
            &:after {
                right: 25px;
            }
        }
    }
}
</style>

<template>
    <footer class="footer">
        <div class="footer__container">
            <div class="footer__body">
                <div class="footer__menu menu-footer">
                    <div class="menu-footer__column menu-footer__column_logo">
                        <a href="#" class="footer__logo"><img src="../assets/img/footer/logo.svg" alt="" /></a>
                        <ul class="menu-footer__list">
                            <li class="menu-footer__item">
                                <a href="tel:+380937563496" class="menu-footer__link menu-footer__link_phone">+38 (093) 756 34 96</a>
                                <!-- <small style="width: 100%">Тільки вхідні дзвінки (не підтримує Viber або Telegram)</small> -->
                            </li>

                            <li class="menu-footer__item">Тільки вхідні дзвінки (не підтримує Viber або Telegram)</li>
                            <li class="menu-footer__item">Щоб звʼязатись в Telegram або Viber натисніть на іконку нижче🫶</li>
                            <li class="menu-footer__item">
                                <a target="_blank" href="https://t.me/+380501744579"><img width="35" src="../assets/img/telegram.svg" alt="" /></a>
                                <a target="_blank" href="viber://chat?number=+380951032040"><img width="35" src="../assets/img/viber.svg" alt="" /></a>
                            </li>
                        </ul>
                    </div>
                    <div class="menu-footer__column">
                        <a href="#" class="menu-footer__mainlink">Головна</a>
                        <div class="menu-footer__title">Інформація</div>
                        <ul class="menu-footer__list">
                            <router-link :to="{ name: 'Offerta' }" class="menu-footer__link">Угода користувача</router-link>
                            <router-link :to="{ name: 'Delivery' }" class="menu-footer__link">Доставка та оплата</router-link>
                            <router-link :to="{ name: 'return-policy' }" class="menu-footer__link">Правила повернення </router-link>
                            <router-link :to="{ name: 'Contacts' }" class="menu-footer__link">Контакти</router-link>
                        </ul>
                    </div>
                    <!-- <div class="menu-footer__column">
            <div class="menu-footer__title">Мій профіль</div>
            <ul class="menu-footer__list">
              <li class="menu-footer__item"><a href="#" class="menu-footer__link">Вхід та реєстрація </a></li>
              <li class="menu-footer__item"><a href="#" class="menu-footer__link">Забули пароль?</a></li>
            </ul>
          </div> -->
                    <div class="menu-footer__column">
                        <div class="menu-footer__title">Приєднатися до нас</div>
                        <ul class="menu-footer__list">
                            <li class="menu-footer__item"><a href="https://instagram.com/le_perle.ua?igshid=MzRlODBiNWFlZA==" class="menu-footer__link _icon-instagram" target="_blank">Instagram </a></li>
                            <li class="menu-footer__item"><a href="https://www.facebook.com/profile.php?id=61551288381490" class="menu-footer__link _icon-facebook" target="_blank">Facebook</a></li>
                        </ul>
                    </div>
                    <div class="menu-footer__column menu-footer__column_form">
                        <div class="menu-footer__title">Підпишись та дізнавайся першим про акції та знижки</div>
                        <div class="menu-footer__form form-footer">
                            <input type="text" class="form-footer__input" placeholder="Ваш E-mail" />
                            <button class="form-footer__btn _icon-next"></button>
                        </div>
                    </div>
                    <div class="menu-footer__column menu-footer__column_hide">
                        <div class="footer__pays">
                            <div class="footer__pay"><img src="../assets/img/footer/mastercard.svg" alt="" width="32" /></div>
                            <div class="footer__pay"><img src="../assets/img/footer/visa.svg" alt="" width="32" /></div>
                            <div class="footer__pay"><img src="../assets/img/footer/monopay.webp" alt="" width="32" /></div>
                            <div class="footer__pay"><img src="../assets/img/footer/wayforpay.svg" alt="" width="32" /></div>
                        </div>
                    </div>
                </div>
                <div class="footer__pays footer__pays_hide">
                    <div class="footer__pay"><img src="../assets/img/footer/mastercard.svg" alt="" width="32" /></div>
                    <div class="footer__pay"><img src="../assets/img/footer/visa.svg" alt="" width="32" /></div>
                    <div class="footer__pay"><img src="../assets/img/footer/monopay.webp" alt="" width="32" /></div>
                    <div class="footer__pay"><img src="../assets/img/footer/wayforpay.svg" alt="" width="65" /></div>
                </div>
                <div class="footer__copy">
                    <div>©2024 All Rights Reserved. Le’Perle.</div>
                    <div>Designed by Katarina Tokar</div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default { name: "AppFooter" };
</script>

<style></style>

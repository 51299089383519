<template>
    <main class="page">
        <section class="offerta">
            <div class="offerta__container">
                <div field="text" class="t-text t-text_md" style="">
                    <br /><br />
                    <p style="text-align: center">
                        <strong style="font-size: 22px"><span class="ql-cursor"></span>{{ returnContent.title }}</strong>
                    </p>
                    <br />
                    <div v-html="returnContent.content"></div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            returnContent: "",
        };
    },
    created() {
        this.fetchreturnContent();
    },
    methods: {
        async fetchreturnContent() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_PROD_API_URL}page/return`);
                this.returnContent = response.data;
            } catch (error) {
                console.error("Error fetching offerta content:", error);
            }
        },
    },
};
</script>

<style scoped>
.t-text {
    line-height: 20px;
}
.text {
    /* font-size: 16px; */
    /* line-height: 24px; */
    /* color: #000; */
}
</style>
